html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------------------------------------------------------------*/

* {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
/*--------------------*/
html,
body {
  overflow-x: hidden;
}

@font-face {
  font-family: "Open Sans Hebrew Condensed";
  src: url("./assets/fonts/OpenSansHebrewCondensed-Bold.ttf") format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-BoldItalic.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-ExtraBold.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-ExtraBoldItalic.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-Italic.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-Light.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-LightItalic.ttf")
    format("truetype");
  src: url("./assets/fonts/OpenSansHebrewCondensed-Regular.ttf")
    format("truetype");
}
a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}
/*---------------------*/
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  background: linear-gradient(180deg, #002a80 0%, #6671c9 23.4%, #0f3381 30.9%);
  display: flex;
  flex-direction: column;
  color: #fff;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  line-height: 1;
}

.container {
  box-sizing: border-box;
  margin: 0px auto;
}

.home_container {
  width: 1152px;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 52px;
}

@media (min-width: 2300px) {
  .home_container {
    width: 1446px;
  }
}

@media (min-width: 3000px) {
  .home_container {
    width: 1740px;
  }
}

@media (max-width: 1200px) {
  .home_container {
    width: 100%;
  }
}

.homeMain {
  flex: 1;
  display: flex;
  align-items: center;
}

.header_container {
  display: flex;
  padding: 52.5px 120px;
  gap: 0px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1400px) {
  .header_container {
    padding: 32px 32px;
  }
}

.header_logo {
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 47.4px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 1400px) {
  .header_logo {
    font-family: Rubik;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.18px;
  }
}

/* Coins2cash */
.header_logo2 {
  background: linear-gradient(102.87deg, #FF42A8 41.29%, #40B8FB 96.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header_groupNav {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  color: #82b6d3;
  display: flex;
  gap: 48px;
  padding: 0 40px 0 40px;
}

@media (max-width: 1400px) {
  .header_groupNav {
    display: none;
  }
}

.group2_registration {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}

.group2_lang {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  cursor: pointer;
  position: relative;
}

.dropLang_container {
  position: absolute;
  top: 47px;
  left: 10px;
  background-color: #1e3779ff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 90;
  width: 220px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.dropLang_item {
  padding: 20px 15px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropLang_item:hover {
  background-color: #2a4f8d;
}

.group2__lang_flag {
  width: 28px;
}

.header_group2 {
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  .header_group2 {
    display: none;
  }
}

@media (min-width: 1401px) {
  .header_burger {
    display: none;
  }
}

@media (min-width: 1401px) {
  .header_profile {
    display: none;
  }
}

.header_burger {
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.header_group2 {
  position: relative;
  z-index: 10;
}

.group2__registration_in {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: left;
  color: #82b6d3;
  padding: 16px 24px;
  background: none;
  cursor: pointer;
}

.group2__registration_up {
  padding: 16px 24px;
  border-radius: 150px;
  border: 1px solid #248dff;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  background: linear-gradient(117.47deg, #ff038b -26.66%, #326cff 137.6%);
  min-width: 116px;
  min-height: 55px;
  cursor: pointer;
}

.decorations {
  position: absolute;
  width: 100vw;
}

.sparkles > * {
  position: absolute;
}

.sparkles_el2 {
  transform: rotate(180deg);
  left: 18vw;
  top: -4vw;
}

.sparkles_el3 {
  transform: rotate(180deg);
  right: 17vw;
  top: -3vw;
}

.sparkles_el4 {
  transform: scaleX(-1);
  top: 15vw;
  right: 0;
}

.sparkles_el1 {
  top: 9.2vw;
}

.sparkles_el {
  width: 20vw;
}

.coins > * {
  position: absolute;
}

.coins_el1 {
  transform: rotate(-90deg);
  top: 47vw;
  left: 15vw;
}

.coins_el2 {
  right: 13vw;
  top: 10vw;
}

.bck {
  z-index: -10;
  position: absolute;
  width: 100vw;
  top: 17vw;
}

@media (max-width: 1500px) {
  .bck {
    top: 30vw;
  }
}

@media (max-width: 1200px) {
  .bck {
    top: 50vw;
  }
}

@media (min-width: 2000px) {
  .bck {
    top: 10vw;
  }
}

@media (min-width: 2300px) {
  .bck {
    top: 4vw;
  }
}

@media (min-width: 2600px) {
  .bck {
    top: 0;
  }
}

@media (min-width: 2900px) {
  .bck {
    top: -6vw;
  }
}

@media (min-width: 3400px) {
  .bck {
    top: -10vw;
  }
}

@media (min-width: 3900px) {
  .bck {
    top: -12vw;
  }
}

@media (max-width: 1300px) {
  .bck {
    top: 20vw;
  }
}

@media (max-width: 1200px) {
  .bck {
    top: 30vw;
  }
}

@media (max-width: 1100px) {
  .bck {
    top: 40vw;
  }
}

@media (max-width: 1000px) {
  .bck {
    top: 50vw;
  }
}

@media (max-width: 900px) {
  .bck {
    top: 55vw;
  }
}

@media (max-width: 800px) {
  .bck {
    top: 60vw;
  }
}

@media (max-width: 700px) {
  .bck {
    top: 70vw;
  }
}

@media (max-width: 650px) {
  .bck {
    top: 80vw;
  }
}

@media (max-width: 600px) {
  .bck {
    top: 100vw;
  }
}

@media (max-width: 515px) {
  .bck {
    top: 110vw;
  }
}

@media (max-width: 490px) {
  .bck {
    top: 120vw;
  }
}

@media (max-width: 480px) {
  .bck {
    top: 140vw;
  }
}

@media (max-width: 430px) {
  .bck {
    top: 160vw;
  }
}

@media (max-width: 400px) {
  .bck {
    top: 180vw;
  }
}

@media (max-width: 370px) {
  .bck {
    top: 200vw;
  }
}
@media (max-width: 340px) {
  .bck {
    top: 220vw;
  }
}

@media (max-width: 320px) {
  .bck {
    top: 240vw;
  }
}

@media (max-width: 300px) {
  .bck {
    top: 260vw;
  }
}

@media (max-width: 280px) {
  .bck {
    top: 280vw;
  }
}

.coins_el {
  width: 3.38vw;
}

.home_h1 {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 68px;
  font-weight: 700;
  line-height: 92.6px;
  color: #ffffff;
}

.home__h1_container {
  margin: 38.5px 0 83.75px 0;
}

.footer_container {
  display: flex;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 1152px;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 2300px) {
  .footer_container {
    width: 1446px;
  }
}

@media (min-width: 3000px) {
  .footer_container {
    width: 1740px;
  }
}

@media (max-width: 1200px) {
  .footer_container {
    width: 100%;
  }
}

.footer_span {
  margin: 0px auto;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #82b6d3;
}

.quick__curency_box_currency_img {
  min-width: 42px;
  max-width: 42px;
}

.footer__logo {
  display: flex;
  flex-direction: column;
  max-width: 196px;
}

.footer__logo_socialMedias {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer_line {
  height: 100px;
  width: 1px;
  background: #25458a;
}

.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #82b6d3;
}

.footer_h5 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}

.footer_section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer_elements {
  display: flex;
  gap: 32px;
}

.footer_elements > * {
  max-width: 86px;
}

.footer__cash2coins_nav_el_2 {
  max-width: 120px;
}

footer {
  background: linear-gradient(180deg, #0f3381 0%, #111c57 100%);
}

.askedQuestions_h3 {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 32px;
}

.askedQuestions {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.askedQuestions_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 24px;
  background: #25458a;
}

.askedQuestions_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.askedQuestions_block_container {
  padding: 8px 24px 8px 24px;
  cursor: pointer;
}

.askedQuestions_block_bottom {
  padding: 10px 52px;
  color: #82b6d3;
  font-family: Roboto;
  font-size: 15.5px;
  font-weight: 400;
}

@media (max-width: 700px) {
  .askedQuestions_block_bottom {
    padding: 10px 0;
  }
}

.askedQuestions_name {
  display: flex;
  align-items: center;
  gap: 18px;
}

.askedQuestion__name_p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.askedQuestions_plus {
  border: 2px solid #90a7e2;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #90a7e2;
  margin-left: 8px;
}
.quick__exchange_container {
  background: #bcd2ff26;
  border-radius: 32px;
  width: 100%;
  height: 800px;
  backdrop-filter: blur(5px);
  border: 3px solid #77a7ff;
  z-index: -11;
  position: absolute;
}

.quick__exchange_border {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border: 11px solid #4377e040;
  padding: 40px;
  display: flex;
  flex-direction: column;
}
.quick__currencyChoioce_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.quick__curency_box {
  width: 464px;
  height: 72px;
  padding: 12px 15px 12px 15px;
  border-radius: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1e377999;
}

.quick__curency_box_currency_name_container {
  display: flex;
  flex-direction: column;
  gap: 6.5px;
  min-width: 80px;
}

.quick__curency_box_currency_send {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #82b6d3;
}

.quick__curency_box_side1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  cursor: pointer;
}

.quick__curency_box_currency_name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: -0.04em;
}

.quick__curency_box_line {
  border: 1px solid #15255a;
  width: 29px;
  height: 0px;
  transform: rotate(90deg);
}

.quick__curency_box_side2 {
  display: flex;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 400;
  line-height: 25.78px;
  text-align: left;
  width: 45%;
  align-items: center;
}

.quick__curency_box_currency_init {
  color: #82b6d3;
}

.quick__exchange_currenct_span {
  margin-top: 12px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
}

.quick__exchange_currenct_span_1 {
  width: 464px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quick__exchange_trcAdress_container {
  height: 72px;
  width: 100%;
  border-radius: 150px;
  padding: 12px 32px;
  background: #1e377999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 0 57px 0;
}

.trcAdress_wallet {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 70%;
}

.trcAdress_imgs {
  display: flex;
  align-items: center;
  gap: 24px;
}

.wallet_input {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  background: none;
  color: #82b6d3;
  width: 100%;
  outline: none;
}

.quick__exchange_done_rect {
  min-width: 34px;
  min-height: 34px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e3379;
  cursor: pointer;
}

.quick__exchange_span {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  color: #82b6d3;
}

.change_span_done_blue {
  color: #6bffed;
}

.quick__exchange_done {
  display: flex;
  align-items: center;
  gap: 24px;
}

.exchange_btn {
  margin: 0px auto;
}

.quick__exchange_btn {
  cursor: pointer;
  margin-top: 28px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  color: #fff;
  width: 304px;
  height: 72px;
  border-radius: 150px;
  padding: 16px 24px;
  border: 1px solid #248dff;
  background: rgb(255, 209, 45);
  background: -moz-radial-gradient(
    circle,
    rgba(255, 209, 45, 1) 0%,
    rgba(255, 3, 139, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 209, 45, 1) 0%,
    rgba(255, 3, 139, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(255, 209, 45, 1) 0%,
    rgba(255, 3, 139, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd12d",endColorstr="#ff038b",GradientType=1);
}
.rates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 44px;
}

.currency__el_img {
  width: 24px;
  height: 24px;
}

.rates__curr_el {
  cursor: pointer;
  width: 110px;
  height: 48px;
  border-radius: 150px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
}

.rates_currency_container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.rates_currency_container_mob {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px 0 8px;
  justify-content: space-between;
  max-width: 550px;
}

@media (max-width: 1200px) {
  .rates_span {
    margin-bottom: 32px;
  }
}

.rates_span {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 32px;
  font-weight: 700;
  line-height: 43.58px;
}

.rates__current_curr {
  border-radius: 150px;
  border: 1px solid #248dff;
  background: linear-gradient(117.47deg, #ff038b -26.66%, #326cff 137.6%);
}

.exchange_container {
  width: 100%;
}

.account__value {
  font-weight: 600;
}

.exchange_container_padding {
  display: flex;
  flex-direction: column;
  padding: 51px;
}
.countryToUsdt {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 48px;
}

.countryToUsdt_block {
  border: 1px solid #ffffff0d;
  width: 270px;
  height: 180px;
  padding: 24px;
  gap: 8px;
  border-radius: 24px;
  backdrop-filter: blur(5px);
  background: #bcd2ff26;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  justify-content: space-between;
}

.countryToUsdt__block_side1_left_abr {
  font-size: 18px;
}

.countryToUsdt__block_side1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.countryToUsdt__block_side1_left {
  display: flex;
  align-items: center;
  gap: 14px;
}

.countryToUsdt__block_line {
  background: #e6e6e60d;
  height: 1px;
  width: 100%;
}

.countryToUsdt__block_side2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.countryToUsdt__block_side2_txt1 {
  color: #40b8fb;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
}

.since_h2 {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.since {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.since_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}

.since_mousePic {
  background: linear-gradient(
    180deg,
    #041c56 50.91%,
    rgba(4, 28, 86, 0) 79.29%
  );
  border: 1px solid #ffffff0d;
  width: 368px;
  height: 419px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 3000px) {
  .since_mousePic {
    width: 500px;
    height: 570px;
  }
}

.since__mouse_h5 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
}

.since__mouse_p {
  font-family: Roboto;
  font-size: 15.5px;
  font-weight: 400;
  color: #82b6d3;
}

.since__mouse_txt {
  padding: 0 24px 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.recent_container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
  align-items: center;
}

.recent_h3 {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 32px;
  font-weight: 700;
}

.recent_block {
  width: 100%;
  border-radius: 24px;
  background: #25458a;
}

.recent__block_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px 8px 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #82b6d3;
}

.blue_footer {
  background: #1f3e83;
}

.recent_block .recent__block_line.blue_footer:last-child {
  border-radius: 0 0 24px 24px;
}

.recent__block_line_side_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.recent__block_line_side_container_left {
  display: flex;
  align-items: center;
  gap: 32px;
}

.recent__block_line_side_container_right_crypto {
  width: 40px;
}

.recent__block_line_side_container_right_value {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.recent__block_line_arr {
  margin-left: 32px;
  margin-right: 32px;
}

@media (max-width: 1400px) {
  .home_h1 {
    display: none;
  }
}

.home_h1_mobile {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 32px;
  font-weight: 700;
  line-height: 43.58px;
  text-align: center;
}

@media (max-width: 1400px) {
  .home__h1_container {
    margin: 0 0 38px 0;
  }
}

@media (max-width: 1200px) {
  .quick__exchange_currenct_span_1 {
    display: none;
  }
}

@media (max-width: 1200px) {
  .quick__exchange_currenct_span_1_2 {
    display: none;
  }
}

@media (max-width: 1200px) {
  .quick__exchange_currenct_span_1_1_isInMobile {
    display: block;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .quick__exchange_currenct_span {
    padding: 0 15px 0 15px;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .quick__exchange_trcAdress_container {
    padding: 24px 18px 24px 18px;
    margin: 28px 0 28px 0;
  }
}

@media (max-width: 700px) {
  .quick__exchange_done {
    align-items: start;
  }
}
@media (max-width: 700px) {
  .exchange_container_padding {
    padding: 36px 12px 32px 12px;
  }
}

@media (max-width: 700px) {
  .exchange_btn {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .quick__exchange_btn {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .rates_span {
    font-family: "Open Sans Hebrew Condensed";
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 1200px) {
  .rates {
    flex-direction: column;
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .countryToUsdt {
    flex-wrap: nowrap;
    margin-bottom: 24px;
    white-space: nowrap;
    width: 100%;
    padding: 0 16px 0 16px;
    overflow-x: auto;
  }
}

@media (max-width: 700px) {
  .since_h2 {
    font-family: "Open Sans Hebrew Condensed";
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
  }
}
@media (max-width: 700px) {
  .since {
    gap: 16px;
  }
}

@media (max-width: 1200px) {
  .since_content {
    gap: 30px;
    padding: 0 16px 0 16px;
    overflow-x: auto;
  }
}

@media (max-width: 700px) {
  .since_content {
    padding: 0 8px 0 8px;
    flex-direction: column;
    gap: 8px;
  }
}
@media (max-width: 700px) {
  .since_mousePic {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 700px) {
  .since_mouse {
    width: 100%;
  }
}

@media (min-width: 3000px) {
  .since_mouse {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .since__mouse_txt {
    padding-bottom: 27.5px;
  }
}
@media (max-width: 700px) {
  .wallet_input {
    font-size: 15px;
    font-weight: 400;
  }
}

.wallet_img {
  width: 31px;
}

@media (max-width: 700px) {
  .wallet_img {
    display: none;
  }
}
@media (max-width: 700px) {
  .recent_container {
    gap: 16px;
    margin-top: 16px;
  }
}
@media (max-width: 700px) {
  .recent_h3 {
    font-family: "Open Sans Hebrew Condensed";
    font-size: 24px;
    font-weight: 700;
  }
}
@media (max-width: 700px) {
  .askedQuestions_h3 {
    font-family: "Open Sans Hebrew Condensed";
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
    max-width: 80%;
  }
}
@media (max-width: 700px) {
  .home__h1_container {
    width: 70%;
  }
}

@media (max-width: 300px) {
  .home__h1_container {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .quick__currencyChoioce_block {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .quick__curency_box {
    width: 100%;
    padding: 8px 15px 8px 15px;
  }
}

@media (max-width: 1200px) {
  .quick__curency_box_currency_name_container {
    display: none;
  }
}

@media (max-width: 1200px) {
  .quick__curency_box_currency_img {
    max-width: 32px;
    margin-right: 5px;
  }
}

@media (max-width: 1200px) {
  .quick__currencyChoioce_block {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 1200px) {
  .quick__curency_box_line {
    display: none;
  }
}

@media (max-width: 1200px) {
  .quick__curency_box_side1 {
    width: auto;
    justify-content: start;
    border-right: 1px solid #15255a;
    padding-right: 8px;
  }
}
@media (max-width: 1200px) {
  .quick__curency_box_side2 {
    width: auto;
  }
}
@media (max-width: 1200px) {
  .quick__currencyChoioce_autorenew {
    display: none;
  }
}

.quick__currencyChoioce_autorenewMobile_img {
  margin: -30px 0 -30px 0;
  z-index: 10;
  width: 40px;
}

@media (max-width: 900px) {
  .recent__block_line_side_container_left_arrUpDown {
    display: none;
  }
}
@media (max-width: 900px) {
  .recent__block_line_arr {
    margin-left: 3px;
    margin-right: 3px;
  }
}
@media (max-width: 700px) {
  .recent__block_line_side_container_left_time {
    display: none;
  }
}

@media (max-width: 700px) {
  .recent__block_line_side_container_right_success {
    display: none;
  }
}
@media (max-width: 700px) {
  .recent__block_line_side_container_right_value {
    font-size: 13px;
    font-weight: 500;
  }
}
@media (max-width: 700px) {
  .footer_container {
    margin-top: 0;
    margin-bottom: 22px;
  }
}

.recent__block_line_side_container_left_mob_text {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4px;
}

.recent__block_line_side_container_right_value_under_mob {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
}

.recent__block_line_side_container_left_mob_text_right {
  align-items: start;
}

@media (max-width: 700px) {
  .recent__block_line_side_container_right_crypto {
    width: 34px;
  }
}

@media (max-width: 700px) {
  .recent__block_line_side_container_left {
    gap: 8px;
  }
}

@media (max-width: 700px) {
  .home_container {
    margin-bottom: 24px;
  }
}

@media (max-width: 700px) {
  .askedQuestions_block_container {
    padding: 16px 20px 16px 20px;
  }
}

@media (max-width: 700px) {
  .askedQuestion__name_p {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 700px) {
  .askedQuestions {
    margin-bottom: 24px;
  }
}

@media (max-width: 1200px) {
  .footer_span {
    display: none;
  }
}

@media (max-width: 1200px) {
  .footer_line {
    display: none;
  }
}

.footer__top_txt {
  margin-left: 0;
  margin-right: 100px;
}

@media (max-width: 1200px) {
  .footer__top_txt {
    display: none;
  }
}

@media (max-width: 1200px) {
  .footer_top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer__top_txt_mob {
  width: 100%;
  padding: 0 30px 0 30px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 50px;
}

.footer__cash2coins_nav_mob {
  color: #82b6d3;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.footer__h5_mob {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #fff;
}

@media (min-width: 1401px) {
  .home_h1_mobile {
    display: none;
  }
}

@media (min-width: 701px) {
  .recent__block_line_side_container_right_value_under_mob {
    display: none;
  }
}

@media (min-width: 1201px) {
  .footer__top_txt_mob {
    display: none;
  }
}

@media (min-width: 1201px) {
  .footer_top {
    line-height: 24px;
  }
}

@media (min-width: 1201px) {
  .quick__currencyChoioce_autorenewMobile_img {
    display: none;
  }
}

@media (max-width: 1200px) {
  .coins {
    display: none;
  }
}

@media (max-width: 1200px) {
  .sparkles_el {
    top: 5vw;
  }
}

@media (max-width: 700px) {
  .sparkles_el {
    width: 30vw;
    top: 8vw;
  }
}

@media (max-width: 500px) {
  .sparkles_el {
    width: 50vw;
    top: 10vw;
  }
}

@media (max-width: 400px) {
  .sparkles_el {
    width: 80vw;
    top: 15vw;
  }
}

@media (max-width: 500px) {
  .sparkles_el2 {
    display: none;
  }
  .sparkles_el3 {
    display: none;
  }
}

@media (max-width: 1200px) {
  .coins {
    display: none;
  }
}
@media (max-width: 1200px) {
  .coins {
    display: none;
  }
}
@media (max-width: 1200px) {
  .coins {
    display: none;
  }
}
@media (max-width: 1200px) {
  .coins {
    display: none;
  }
}

@media (max-width: 900px) {
  .quick__exchange_container {
    height: 650px;
  }
}

@media (max-width: 700px) {
  .quick__exchange_container {
    height: 600px;
  }
}
.bckOther {
  z-index: -10;
  position: absolute;
  width: 100vw;
  max-height: 100vh;
  object-fit: cover;
}

.other_container {
  margin-bottom: 180px;
}

.order__h1_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 68px;
  font-weight: 700;
  margin: 38px 0 65px 0;
  text-align: center;
}

.quick__exchange_time {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 0 20px 0 20px;
}

.exchange__time_el {
  display: flex;
  gap: 24px;
  width: 47%;
}

.exchange__time_span {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.order_blue {
  color: #b2cfe0;
}

.quick__exchange_curr {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin-bottom: 36px;
}

.order_currency {
  padding: 23px 20px 23px 20px;
  border-radius: 20px;
  background: #1e377999;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.order__currency_left {
  display: flex;
  gap: 16px;
  align-items: center;
}

.order__curr_end {
  text-align: end;
}

.order__currency_left_txt {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.order__currency_left_txt {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.order__currency_left_txt_p_send {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #82b6d3;
}

.order_persInfo {
  display: flex;
  align-items: center;
  gap: 21px;
  flex-direction: column;
  margin-bottom: 36px;
}

.order__persInfo_h3 {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
}

.order_persInfo_content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
}

.fallback {
  background: #4B5EB6;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.order__persInfo_el {
  display: flex;
  gap: 5px;
  align-items: center;
}

.order__persInfo_emailWord {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.order__persInfo_email {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #b2cfe0;
}

.order_status {
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  background: #1e377999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #b2cfe0;
  margin-bottom: 62px;
}

.order_instruction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 36px;
}

.order__instruction_h3 {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
}

.order__instruction_punkty {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.order__instruction_punkt {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 45%;
}

.punkt_number {
  background: linear-gradient(102.87deg, #ff038b 41.29%, #326cff 96.96%),
    linear-gradient(165.87deg, #ff038b 10.2%, #326cff 131.2%);
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.punkt_p {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  color: #b2cfe0;
}

.exchange_container_padding {
  border-radius: 32px;
}

.order_padding {
  backdrop-filter: blur(10px);
}
.wrapper {
  position: relative;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 10;
}

.exchange__time_img {
  height: 42px;
  width: 42px;
}

@media (max-width: 1000px) {
  .time_line {
    display: none;
  }
}

@media (max-width: 1000px) {
  .order_exch_curr {
    display: none;
  }
}

@media (max-width: 1000px) {
  .order__h1_container {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 38px;
  }
}

@media (max-width: 1000px) {
  .quick__exchange_time {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .exchange__time_img {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 1000px) {
  .order_padding {
    padding: 26px 11px 26px 11px;
  }
}

@media (max-width: 1000px) {
  .bckOther {
    top: 300px;
  }
}

@media (max-width: 600px) {
  .bckOther {
    top: 400px;
  }
}

@media (max-width: 350px) {
  .bckOther {
    top: 500px;
  }
}

@media (max-width: 1000px) {
  .exchange__time_el {
    width: 100%;
    gap: 5px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
  }
}
@media (max-width: 1000px) {
  .order__persInfo_h3 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
  }
}
@media (max-width: 1000px) {
  .order_persInfo {
    gap: 16px;
  }
}
@media (max-width: 1000px) {
  .order_persInfo_content {
    flex-direction: column;
    margin: 0 auto;
    width: auto;
    gap: 10px;
  }
}
@media (max-width: 1000px) {
  .order__persInfo_el {
    width: auto;
  }
}
@media (max-width: 1000px) {
  .order_status {
    padding-top: 0;
    padding-bottom: 0;
    background: none;
  }
}
@media (max-width: 1000px) {
  .order__status_p {
    display: none;
  }
}

.order__status_p_mob {
  display: block;
}

@media (max-width: 1000px) {
  .order__instruction_h3 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 1000px) {
  .order__instruction_punkty {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  .order__instruction_punkt {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .order_btn {
    margin-top: 0;
  }
}
@media (max-width: 1000px) {
  .other_container {
    margin-bottom: 56px;
  }
}

.mob_oplachena {
  color: #39ec22;
}

.quick__exchange_curr_mob {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.order_currency_mob {
  width: 48%;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: end;
}

.order_currency_mob_img {
  max-width: 28px;
  max-height: 28px;
}

.order_currency_arr_right_mob {
  margin: 0 10px 0 10px;
}

.order_currency_mob_txt {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.order_currency_mob_txt_down {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: #82b6d3;
}

.order_currency_mob_right {
  justify-content: start;
}

.quick__exchange_container_order {
  height: 500px;
}

@media (max-width: 1250px) {
  .quick__exchange_container_order {
    height: 400px;
  }
}

@media (max-width: 1100px) {
  .quick__exchange_container_order {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .quick__exchange_container_order {
    height: 400px;
  }
}

@media (min-width: 1001px) {
  .quick__exchange_curr_mob {
    display: none;
  }
}

@media (min-width: 1001px) {
  .order__status_p_mob {
    display: none;
  }
}

.pay_txt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #b2cfe0;
}

.txt__top_h5 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.pay__txt_top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.pay_instruction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #1e377999;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 34px;
}

.pay__instruction_el {
  display: flex;
  align-items: center;
  gap: 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  max-width: 30%;
}

.white {
  color: #fff;
  font-weight: 600;
}

.pay__waning_txt {
  margin-bottom: 48px;
}

.qrSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 54px;
}

.qrSection_content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.qrSection_txt {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.qrSection__valuta_word {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}
.qrSection__reqizity_word {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.qrSection_value {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
}

.qrSection_copyAddress {
  color: #ff038b;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.pay__txt_bottom_container {
  padding: 0 44px 0 44px;
}

.pay_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 44px 0 44px;
  margin-top: 30px;
}

@media (max-width: 1000px) {
  .pay_btn_container * {
    width: 100%;
  }
}

.pay__btnCancel {
  background: #23517b;
}

@media (max-width: 1000px) {
  .pay__txt_top {
    padding: 0 20px 0 20px;
    gap: 11px;
  }
}

@media (max-width: 1000px) {
  .txt__top_h5 {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 1000px) {
  .pay__instruction_img {
    display: none;
  }
}

@media (max-width: 1000px) {
  .pay_instruction {
    flex-direction: column;
    padding: 12px;
    gap: 24px;
  }
}

@media (max-width: 1000px) {
  .pay__instruction_el {
    min-width: 100%;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (max-width: 1000px) {
  .qrSection_content {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .pay__waning_txt {
    margin-bottom: 24px;
  }
}

@media (max-width: 1000px) {
  .qrSection_txt {
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 1000px) {
  .qrSection_valuta {
    display: flex;
  }
}
@media (max-width: 1000px) {
  .qrSection__reqizity_code {
    word-break: break-all;
  }
}
@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .pay__txt_bottom_container {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .pay_btn_container {
    flex-direction: column;
    padding: 0;
    gap: 12px;
  }
}

@media (max-width: 1000px) {
  .pay_btn {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}

@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}
@media (max-width: 1000px) {
  .qrSection {
    margin-bottom: 40px;
  }
}

.history_padding {
  padding: 15px;
  border-radius: 32px;
}

.history_table {
  border-radius: 32px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  background: #25458a26;
}

.table__static_line {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  background: #25458a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

@media (max-width: 900px) {
  .table__static_line {
    font-size: 12px;
    font-weight: 400;
    padding: 10px 15px;
  }
}

@media (max-width: 900px) {
  .table__content_line_el_from {
    padding-left: 10px;
  }
}

@media (max-width: 550px) {
  .table__static_line {
    font-size: 10px;
    font-weight: 300;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

@media (max-width: 400px) {
  .table__static_line_el_amount {
    padding-left: 5px;
  }
}

.table__content_line_el_value {
  max-width: 180px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 5px;
}

.table_blue {
  color: #b2cfe0;
}

.table__static_line > * {
  width: calc(100% / 6);
}

.table__content_line {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 200;
  padding: 14px 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 550px) {
  .table__content_line {
    padding: 15px 5px;
  }
}

@media (max-width: 550px) {
  .table__content_line {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .table__content_line_el_date {
    font-size: 8px;
  }
}

.table__content_line > * {
  width: calc(100% / 6);
}

.history__table_darker_bck {
  background: #1d368059;
}

.history__blue_font {
  color: #b2cfe0;
}

.history__green_font {
  color: #56ff2b;
}
.login_container {
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 105;
}

.login_content {
  background: #344ea5;
  color: #b2cfe0;
  border-radius: 32px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 413px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
}

.login__word_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginInput_withIcon {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #1e377999;
  border-radius: 100px;
  padding: 10px 20px;
}

.loginInput_icon {
  width: 24px;
  height: 24px;
}

.loginInput_input {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  width: 100%;
  height: 44px;
  font-size: 15px;
}

.login_rememberMe {
  display: flex;
  align-items: center;
  gap: 10px;
}

.login_word {
  font-family: Open Sans Hebrew Condensed;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}

.loginInput__login_word {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.loginInput_chooseAuth {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_loginInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

.done_rect {
  min-width: 25px;
  min-height: 25px;
  border-radius: 6px;
  background: #27408b;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .rates_currency_container {
    max-width: 90%;
    overflow-x: auto;
  }
}

@media (min-width: 1201px) {
  .rates_currency_container {
    max-width: 60%;
    overflow-x: auto;
  }
}

/* Для Firefox */
.rates_currency_container {
  scrollbar-width: thin;
  scrollbar-color: #ff03895d #326cff69;
}

.burgerActive {
  width: 80vw;
  height: 100%;
  background: #25306d;
  z-index: 100;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.burgerActive_effects {
  width: 100vw;
  backdrop-filter: blur(20px) brightness(0.6);

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;
}

.flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.burgerShow_nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.burger__login_container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}

.burger_signIn {
  padding: 0 24px 0 0;
}

.burger_flag {
  display: flex;
  gap: 8px;
  margin-top: 20px;
  cursor: pointer;
  position: relative;
}
.hidden {
  display: none;
}

.quick__curency_box_currency_value_input {
  background: none;
  outline: none;
  font-size: 22px;
  font-weight: 400;
  line-height: 25.78px;
  text-align: right;
  color: #fff;
  max-width: 70%;
}

.quick__curency_box_currency_value_input::-webkit-inner-spin-button,
.quick__curency_box_currency_value_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quick__curency_box_currency_value_input {
  -moz-appearance: textfield;
}

@media (max-width: 1200px) {
  .quick__curency_box_currency_value_input {
    max-width: none;
  }
}

@media (max-width: 520px) {
  .quick__curency_box_currency_value_input {
    max-width: 100px;
  }
}

.quick__curency_dropdown {
  position: absolute;
  background-color: #1e3779ff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 90;
  width: 100%;
  top: 47px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.quick__curency_dropdown_item {
  padding: 20px 15px;
  color: #fff;
  cursor: pointer;
}

.quick__curency_dropdown_item:hover {
  background-color: #2a4f8d;
}

.quick__curency_box_side1 {
  position: relative;
}

.quick__exchange_btn_nonActive {
  filter: brightness(0.8);
}

.quick__exchange_done_rect_login {
  min-width: 26px;
  min-height: 26px;
}

.mailNameTelega_container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
  margin-bottom: 28px;
  margin-top: -29px;
}

.mailNameTelega_container > * {
  background: #1e377999;
  border-radius: 150px;
  flex: 1;
  gap: 11px;
  padding: 15px 23px;
  display: flex;
  align-items: center;
}

.groupNav_el {
  cursor: pointer;
}

@media (max-width: 700px) {
  .mailNameTelega_container {
    margin-top: 0;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .mailNameTelega_container > * {
    width: 100%;
  }
}

.wallet__img_3inps {
  display: block;
}

.mailNameTelega__mail_container {
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.mailNameTelega__mail_container.highlight {
  box-shadow: 0 0 10px #ddc143;
}

.inputErrorEmail {
  box-shadow: 0 0 10px #ff038b;
}

.errorTextEmail {
  color: #fff;
  font-size: 14px;
  text-wrap: nowrap;
}

.inputCardAlert {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 25px;
}

@media (min-width: 701px) {
  .inputCardAlert {
    margin-top: -40px;
  }
}

.invalidLogin {
  color: rgba(255, 3, 139, 1);
}

.loginTxtHeader {
  font-size: 24px;
}

.loginTxtHeader_conyForDropdown {
  cursor: pointer;
  position: relative;
}

.quick__curency_dropdown_header {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 28px;
  font-size: 18px;
}

.loginHeaderTxt_container_burger {
  padding-left: 0;
  width: auto;
}

.quick__curency_dropdown_header_burger {
  left: 0;
  max-width: 100px;
}

.quick__curency_box_currency__container {
  padding-left: 10px;
  flex: 1;
}

.quick__curency_box_currency_value2 {
  max-width: 130px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #ff03895d #326cff69;
}

.quick__curency_box_currency_value2::-webkit-scrollbar {
  height: 6px;
}

.quick__curency_box_currency_value2::-webkit-scrollbar-track {
  background: transparent;
}

.quick__curency_box_currency_value2::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.quick__curency_box_currency_value2:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}

@media (max-width: 1200px) {
  .quick__curency_box_currency_value2 {
    max-width: none;
  }
}

@media (max-width: 600px) {
  .quick__curency_box_currency_value2 {
    max-width: 200px;
  }
}

@media (max-width: 450px) {
  .quick__curency_box_currency_value2 {
    max-width: 100px;
  }
}

.quick__curency_dropdown_item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quick__curency_dropdown_item_img {
  width: 24px;
}

.header_profile {
  cursor: pointer;
}

.countryToUsdt__block_side2_graph_graphRelative {
  position: relative;
}

.countryToUsdt__block_side2_graph {
  position: absolute;
  top: -25px;
  left: -105px;
}

.created {
  color: #ffd50b;
}

.pending {
  color: #ffd50b;
}

.success {
  color: #56ff2b;
}

.paid {
  color: #56ff2b;
}

.cancelled {
  color: #ff9090;
}

.quick__exchange_currenct_span_1_2_high {
  color: #ddc143;
}

.loading_history {
  font-family: "Open Sans Hebrew Condensed";
  font-size: 68px;
  font-weight: 700;
  line-height: 92.6px;
  color: #ffffff;
}

.pageScroller_left {
  transform: rotate(180deg);
}

.table_pageScroller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  color: #b2cfe0;
}

.pageScroller_number {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pageScroller_number_active {
  color: #57b7ee;
}

.pageScroller_arr {
  width: 30px;
  cursor: pointer;
}

.table_zeroTransactions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.table_zeroTransactions_p {
  font-size: 32px;
  font-weight: 200;
  line-height: 92.6px;
  color: #ffffff;
}

.logInPlz_history {
  font-size: 24px;
}

.order__currency_left_currImg {
  width: 42px;
}

.loginInput__registr_word {
  cursor: pointer;
}

.countryToUsdt__block_side1_left_coin {
  min-width: 48px;
  max-width: 48px;
}

.faq_container {
  padding-left: 50px;
  padding-right: 50px;
  gap: 20px;
}

.faq_q {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 600;
}

.faq_a {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
}

.affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.affiliate-table th,
.affiliate-table td {
  border: 2px solid #15255a;
  padding: 8px;
  text-align: center;
}

.affiliate-table th {
  background-color: #15255a;
  font-weight: bold;
}

.table__content_line_el-comment {
  line-height: 130%;
}

@media (max-width: 768px) {
  .table__content_line_el {
    font-size: 11px!important;
  }
}

@media (max-width: 768px) {
  .order-history-table {
    overflow-x: auto;
  }

  .order-history-table section {
    width: 200%;
  }
}
